import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Schulden = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Schulden" showCalc={false} />
            <Article>
                <p>
                    Dein Konto ist überzogen, die erste (vielleicht auch schon die zweite) Mahnung für den letzten
                    Onlinekauf flattert ins Haus oder du weißt nicht, wie du die nächste Handyrechnung bezahlen sollst.
                    Sofort macht sich in deinem Kopf eine Angst bemerkbar, die &ndash; seit der Kindheit so vertraut wie
                    gefürchtet &ndash; für die äußerste Ausweglosigkeit steht: Schulden. Doch wie kann man Schulden
                    vermeiden? Und muss man das auch? Ist die negative Konnotation, die das Wort im Alltag zweifellos
                    besitzt, aus wirtschaftlicher Sicht überhaupt gerechtfertigt? Und wenn nicht: woher stammt sie?
                    Diesen Fragen widmen wir uns im folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zur Finanzberatung
                    </a>
                </p>
                <hr />

                <h2>Warum so negativ? – Der Begriff Schulden</h2>
                <p>
                    Wenn Schulden also etwas durchaus Alltägliches sind, wie kommt es dann, dass sie einen derart
                    schlechten Ruf haben? Um diese Frage zu beantworten, müssen wir uns den Begriff etwas näher ansehen
                    und einen (wir versprechen es:)kurzen Gang durch seine Geschichte wagen.
                </p>
                <p>
                    Der unangenehme Beigeschmack, der sich beim Wort Schulden üblicherweise einstellt, ist schon auf den
                    Umstand zurückzuführen, dass sich in darin die Schuld verbirgt: Während der reine Begriffsgehalt
                    also lediglich auf die Pflicht verweist, geliehenes Kapital zurückzuerstatten, so haftet der
                    Tatsache, dass man überhaupt Schulden gemacht hat, gewissermaßen ein moralisches Stigma an. Als
                    Schuldner*in gewinnt man so leicht den Eindruck, etwas getan zu haben, dass moralisch verwerflich
                    ist, ja sogar eine religiöse oder metaphysische Dimension hat – kann Gott kulturgeschichtlich doch
                    durchaus als letzter Gläubiger betrachtet werden. Dabei leiten die Gebrüder Grimm in ihremDeutschen
                    Wörterbuchvon 1838 &bdquo;Schulden&ldquo; von &bdquo;skulan&ldquo; ab – einem germanischen Verb, das
                    lediglich &bdquo;sollen&ldquo; bedeutet bzw. allgemein eine Verpflichtung meint: Keineswegs
                    angenehm, aber noch nicht mit der Negativität besetzt, auf die das Wort heute verweist
                </p>
                <hr />

                <h2>Band der Verbindlichkeiten – Die soziale und wirtschaftliche Funktion von Schulden</h2>
                <p>
                    Dass man als Schuldner*in tatsächlich nicht um das eigene Seelenheil zu bangen braucht, zeigt sich
                    spätestens, wenn man die sozialen und wirtschaftlichen Funktionen berücksichtigt, die Schulden in
                    unserer Gesellschaft besitzen. So weist etwa der Kulturwissenschaftler Joseph Vogl in einem{" "}
                    <a
                        href="https://www.bpb.de/mediathek/244976/schuld-und-schulden-mit-prof-joseph-vogl"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Interview
                    </a>{" "}
                    mit der deutschen Bundeszentrale für politische Bildung (bpb) darauf hin, dass Schulden ein
                    &bdquo;elementares soziales Band&ldquo; sind, dass das Fortbestehen der Gesellschaft sichert, indem
                    es &bdquo;wechselseitige Verbindlichkeiten&ldquo; schafft.
                </p>
                <p>
                    Auch in wirtschaftlicher Hinsicht wäre eine Gesellschaft ohne Schulden höchstwahrscheinlich kaum
                    leistungsfähig: Sind sie doch überhaupt erst die Voraussetzung für Investitionen und ermöglichen es
                    dem*der Gläubiger*in darüber hinaus, Zeit und Zukunft in ökonomischer Hinsicht produktiv zu machen
                    und das eigene Kapital auf diese Weise zu vermehren.
                </p>
                <hr />

                <h2>Was tun bei Schulden?</h2>
                <p>
                    Auch wenn Schulden eine wichtige wirtschaftliche Triebfeder sind, so lassen sich sicherlich
                    angenehmere Situationen denken als die, verschuldet zu sein. Doch welche Maßnahmen kann man im Fall
                    der Fälle ergreifen, um die eigenen Schulden zu minimieren oder gar zu eliminieren? Hier einige
                    Tipps:
                </p>
                <h3>1. Rede offen über deine Situation</h3>
                <p>
                    Gerade bei einem derart negativ besetzten Thema ist der Leidensdruck von Betroffenen oft besonders
                    groß. Es kann daher hilfreich sein, mit Vertrauenspersonen über die eigene Situation zu sprechen und
                    gemeinsam Lösungsansätze zu erarbeiten. Reicht das freundschaftliche Gespräch nicht aus, so kann es
                    helfen, eine professionelle Schuldnerberatung in Anspruch zu nehmen.
                </p>
                <h3>2. Erstelle ein Haushaltsbuch</h3>
                <p>
                    In einem Haushaltsbuch – auch Haushaltsbudget – hältst du sämtliche Einkünfte und Ausgaben fest, um
                    den frei verfügbaren Teil deines Einkommens ermitteln: also jenen Teil, den du nicht für die Deckung
                    laufender Kosten verwendest. Mit diesem kannst du deine Schulden abbezahlen. Addiere dafür einfach
                    deine Einkünfte und ziehe anschließend die Ausgaben ab. So lässt sich auch abschätzen, wie lange du
                    für die Rückzahlung deiner Schulden noch brauchst bzw. ob du die Rückzahlung fristgerecht vornehmen
                    kannst.
                </p>
                <h3>3. Erstelle eine Übersicht über die Gläubiger*innen und ihre Forderungen</h3>
                <p>
                    Nachdem du mit dem Haushaltsbudget den frei verfügbaren Teil deines Einkommens ermittelt hast, gilt
                    es nun, dir einen detaillierten Überblick über deine Schulden zu verschaffen. Mache hierfür am
                    besten eine Liste, in der du sämtliche Gläubiger*innen sowie die jeweils geforderten Beträge
                    festhältst. Ratsam ist es auch, die Kontaktdaten deiner Gläubiger*innen innen in die Liste
                    aufzunehmen und bei dieser Gelegenheit alle Unterlagen zu ordnen, die du von diesen erhalten hast.
                    Sämtliche Dokumente stets griffbereit zu haben, ist insbesondere für Termine bei der
                    Schuldnerberatung essenziell.
                </p>
                <hr />

                <h2>Schulden und Überschuldung – ein wichtiger Unterschied</h2>
                <p>
                    Während Schulden – wie wir gerade gesehen haben – prinzipiell nichts Schlechtes, ja sogar ein
                    wesentlicher Wirtschaftsfaktor sind, verhält es sich mit der Überschuldung anders. Hier ist der*die
                    Schuldner*in nicht mehr in der Lage, den Forderungen des*der Gläubiger*in (fristgerecht)
                    nachzukommen. Die Schulden sind in diesem Fall also höher als das Kapital, über das die verschuldete
                    Person verfügt. Doch auch diese Situation ist keinesfalls ausweglos. Auch hier lassen sich
                    verschiedene Maßnahmen ergreifen:
                </p>
                <h3>1. Wende dich an eine professionelle Schuldnerberatung</h3>
                <p>
                    Setze diesen Schritt so früh wie möglich und ignoriere keinesfalls Rechnungen, die du nicht bezahlen
                    kannst: Mahngebühren lassen den Betrag deiner Schulden weiter ansteigen. Bedenke auch, dass es
                    einige Stellen gibt, die kostenlose Schuldnerberatungen anbieten.
                </p>
                <h3>2. Erstelle ein Haushaltsbudget</h3>
                <p>
                    Wie oben erwähnt, ist es für den ersten Termin bei einer Schuldnerberatung zentral, eine
                    detaillierte und vor allem ehrliche Haushaltsrechnung sowie eine Aufstellung sämtlicher
                    Gläubiger*innen mitsamt den von ihnen geforderten Beträgen vorlegen zu können. Nur so ist den
                    Berater*innen möglich, sich einen Überblick über deine persönliche Situation zu verschaffen und dich
                    individuell zu beraten.
                </p>
                <h3>3. Raus aus der Schuldenfalle: der Schuldenbereinigungsplan</h3>
                <p>
                    Nachdem der*die Berater*in deine Situation analysiert hat, wird er*sie dir eine Strategie vorlegen,
                    mit der du deine Schulden Schritt für Schritt los wirst – den sogenannten Schuldenbereinigungsplan.
                    Idealerweise lässt sich mit einem solchen Plan eine Einigung mit den Gläubiger*innen erzielen,
                    sodass du dich in Zukunft lediglich an diesen halten musst, um deine Schulden sukzessive abzubauen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratungsgespräch vereinbaren
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"schulden"}></BreadcrumbList>
            <ArticleStructuredData page={"schulden"} heading={"Schulden"} />
        </Layout>
    );
};

export default Schulden;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.schulden", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
